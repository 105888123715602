import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInfinitySlider03,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: (
              <>
                小宴会場 <br className="u_sp" />
                ブライズルーム
              </>
            ),
            sub: <>BRIDES ROOM</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/brides/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/brides/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="u_pt50 u_mbLarge">
        <LWrap>
          <CInfinitySlider03
            data={[
              {
                img: {
                  src: '/assets/images/banquet/brides/img_banquet.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="u_mbMedium">
            お支度の整ったご新婦が、挙式までの大事なひとときをご新郎や親御様など、近しい方々とお過ごしいただける専用の控室です。
          </p>

          {/* <COverflowTable>
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th colSpan={4}>ご人数</th>
                  <th rowSpan={2}>広さ(m²)</th>
                  <th rowSpan={2}>天井高</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>全スパン</th>
                  <td>600</td>
                  <td>1,000</td>
                  <td>700</td>
                  <td>1,200</td>
                  <td>1,000</td>
                  <td>6</td>
                </tr>
                <tr>
                  <th>2/3</th>
                  <td>370</td>
                  <td>500</td>
                  <td>470</td>
                  <td>600</td>
                  <td>660</td>
                  <td>6</td>
                </tr>
                <tr>
                  <th>1/2</th>
                  <td>300</td>
                  <td>350</td>
                  <td>300</td>
                  <td>550</td>
                  <td>500</td>
                  <td>6</td>
                </tr>
                <tr>
                  <th>1/3</th>
                  <td>180</td>
                  <td>200</td>
                  <td>180</td>
                  <td>380</td>
                  <td>330</td>
                  <td>6</td>
                </tr>
                <tr>
                  <th>ホワイエ</th>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>380</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </COverflowTable> */}

          {/* <CFixedImg
            width={680}
            img={{
              src: '/assets/images/banquet/brides/img_figure.png',
              alt: '',
            }}
          /> */}
        </LWrap>
      </div>
      {/*
      <section className="u_mbLarge">
        <LWrap>
           <CSectTitle03 title="レイアウト例" />
          <CSimpleCard
            data={[
              {
                img: {
                  src: '/assets/images/banquet/brides/img_example.png',
                  alt: '全スパン　正餐 ○○名',
                },
                title: <>全スパン　正餐 ○○名</>,
              },
              {
                img: {
                  src: '/assets/images/banquet/brides/img_example02.png',
                  alt: '全スパン　正餐 ○○名',
                },
                title: <>全スパン　正餐 ○○名</>,
              },
              {
                img: {
                  src: '/assets/images/banquet/brides/img_example03.png',
                  alt: '（上から）セミナー ○○名　懇親会 ○○名',
                },
                title: <>（上から）セミナー ○○名　懇親会 ○○名</>,
              },
            ]}
          />
        </LWrap>
      </section> */}
      <section>
        <LWrap>
          {/* <CSectTitle
            title={{
              en: 'EQUIPMENT',
              ja: '主要設備',
            }}
          />
          <CTable
            data={[
              {
                title: '音響',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>オーディオミキサー6ch</li>
                      <li> カセットデッキプレーヤー</li>
                      <li>CDプレーヤー</li>
                      <li>スピーカー</li>
                      <li>マイクロフォン</li>
                      <li>ワイヤレスマイクロフォン</li>
                    </ul>
                  </>
                ),
              },
              {
                title: '照明',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>
                        調光卓＜2段プリセット48ch(サブマス10ch)、2段プリセット24ch(サブマス10ch)
                      </li>
                      <li> 演出調光回路 30A</li>
                      <li>ホリゾントライト／フレネルライト</li>
                      <li>ピンスポットライト</li>
                      <li>パーライト</li>
                      <li>
                        仮設電源盤
                        <br />
                        主幹 1φ3W 100/200V 300A/分岐 1φ3W 100/200V
                        75A×6/主幹3φ3W 200V 150A/分岐 3φ3W 200V 50A×3 スクリーン
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: 'その他',
                content: (
                  <>
                    プロジェクター※有料
                    <br />
                    スクリーン※有料
                    <br />
                    看板・案内板※有料
                  </>
                ),
              },
            ]}
          /> */}
          <CBtnList
            data={[
              {
                label: 'CO₂ゼロ MICE®のご案内',
                size: 'large',
                link: {
                  href: '/banquet/co2zero_mice/',
                },
                color: 'borderBlack',
              },
            ]}
          />
          {/* <CInlineLinkList
            exClass="u_mt30"
            data={[
              {
                label: 'テレビ会議システムのご案内',
                link: {
                  href: '#',
                  blank: true,
                },
                icon: 'document',
              },
            ]}
          /> */}
        </LWrap>
      </section>
      <LVenue />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
